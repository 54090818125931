import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Subscribe from "../components/subscribe";
import { rhythm } from "../utils/typography";

const AboutPage = (props) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout copyright={false} location={props.location} title={siteTitle}>
      <SEO title="About" image={"/ncjamieson-card.png"} />
      <h1
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(1),
        }}
      >
        About Me
      </h1>
      <p>
        At the moment, I would describe myself as a front-end developer, but I
        have experience in back-end, desktop, mobile and embedded development.
      </p>
      <p>
        I’ve been writing software for more than two decades and I’ve developed
        manufacturing, geospatial and financial applications.
      </p>
      <p>
        I’m a user of and an advocate for open-source software and I’m a member
        of the <a href="https://github.com/ReactiveX/RxJS">RxJS</a> core team.
        I’ve also contributed to several other open-source packages, most
        notably to{" "}
        <a href="https://github.com/angular/angularfire2">AngularFire2</a> and{" "}
        <a href="https://github.com/TypeStrong/tsify">tsify</a> — a package that
        I now maintain.
      </p>
      <p>
        I’ve authored a number of JavaScript/TypeScript packages. Some are
        focused on simplifying testing for projects that use TypeScript, RxJS or
        Firebase; others are developer tools or libraries of reusable code.
      </p>
      <p>
        In 2017, I started writing articles on{" "}
        <a href="https://medium.com/@cartant/latest">Medium</a>. My initial
        articles dealt with the RxJS tools I’d authored; my subsequent articles
        showed how RxJS could be used to solve particular problems, documented
        my experiences of testing RxJS-based applications and explained some of
        the less-well-known concepts in RxJS.
      </p>
      <p>
        Writing is something I enjoy and providing simple, clear explanations
        for seemingly complicated topics is something I relish.
      </p>
      <p>
        I no longer publish articles on Medium. Instead, they're published
        here — on my personal blog. If the topics interest you, subscribe to my
        newsletter — or follow me on{" "}
        <a href="https://twitter.com/ncjamieson">Twitter</a> — to be notified of
        new articles.
      </p>
      <Subscribe tags={[]} />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
